module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ueberdiespree.de","short_name":"ueberdiespree.de","start_url":"/","background_color":"#ececec","theme_color":"#ececec","display":"minimal-ui","icons":[{"src":"/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/icon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
